/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Button, Image, Text, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Office Catering"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1gf7ab1 --style2 --full pb--60 pt--60" name={"hero"} border={""} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/118df65d6c3d4ee68fa7521578ac6e6a_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/118df65d6c3d4ee68fa7521578ac6e6a_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/118df65d6c3d4ee68fa7521578ac6e6a_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/118df65d6c3d4ee68fa7521578ac6e6a_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/118df65d6c3d4ee68fa7521578ac6e6a_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/118df65d6c3d4ee68fa7521578ac6e6a_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/118df65d6c3d4ee68fa7521578ac6e6a_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/118df65d6c3d4ee68fa7521578ac6e6a_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--102 title-box--invert" content={"Office Catering"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"allq2j1t7dh"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--stretch" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box ff--1" style={{"maxWidth":479,"paddingRight":0}} content={"V Oliva Catering chápeme, že každý pracovní den může být náročný, a právě proto jsme tady.<br>"}>
              </Subtitle>

              <Subtitle className="subtitle-box ff--1" style={{"maxWidth":479,"paddingRight":0}} content={"Poskytneme vám nezapomenutelný zážitek ve světě chutí a gastronomických radostí. S naším odborným přístupem k office cateringu nabízíme nejen lahodné jídlo, ale i pohodlí přímo do vašich kanceláří."}>
              </Subtitle>

              <Button className="btn-box fs--12" use={"page"} href={"/officecatering#vcbhlz9jitq"} content={"Chci catering do kanceláře"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/18947/e48bc06179b448deb43fa1bdfe5b1857_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/18947/e48bc06179b448deb43fa1bdfe5b1857_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/e48bc06179b448deb43fa1bdfe5b1857_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/e48bc06179b448deb43fa1bdfe5b1857_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/e48bc06179b448deb43fa1bdfe5b1857_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/e48bc06179b448deb43fa1bdfe5b1857_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"allq2j1t7dh"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--stretch" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/18947/cd131f556a0a47fba306439cbf7cd579_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":552}} srcSet={"https://cdn.swbpg.com/t/18947/cd131f556a0a47fba306439cbf7cd579_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/cd131f556a0a47fba306439cbf7cd579_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/cd131f556a0a47fba306439cbf7cd579_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/cd131f556a0a47fba306439cbf7cd579_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/cd131f556a0a47fba306439cbf7cd579_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box ff--1" content={"Snídaně, která nastartuje den<br>"}>
              </Subtitle>

              <Text className="text-box ff--2" content={"Rozjeďte váš pracovní den správným způsobem s našimi bohatými a zdravými snídaněmi. Od plněných croissantů, obložených chlebů, wrapů až po lahodné smoothie, připravené s láskou a péčí našich kuchařů.<br><br>Pro milovníky sladkého pak máme připravenou domácí bábovku, drobenkový koláč a výbornou granolu.&nbsp;Vaše snídaně nemusí být pouze povinností, ale skvělým začátkem pro každý den.<br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn3 fs--12" use={"page"} href={"/officecatering#vcbhlz9jitq"} content={"Objednat snídani"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"allq2j1t7dh"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--stretch" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box ff--1" content={"Obědy, které posilní"}>
              </Subtitle>

              <Text className="text-box" content={"Nudné obědové přestávky jsou minulostí! Představte si každý svůj oběd jako malé dobrodružství plné chutí a kulinářského umění. S naším širokým spektrem obědů přinášíme do vaší kanceláře opravdovou paletu lahůdek.<br><br>Od lehkých a osvěžujících salátů až po bohaté hlavní chody, každý den vám nabízíme kulinářský zážitek, který zvedne váš oběd na novou úroveň. Proč tedy nezkusit něco nového každý den a dopřát si oběd, který bude opravdovým požitkem pro vaše chuťové buňky? Vaše chutě si zaslouží trochu dobrodružství!"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/18947/86b808942c5047afb3b22beafce234f8_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":552}} srcSet={"https://cdn.swbpg.com/t/18947/86b808942c5047afb3b22beafce234f8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/86b808942c5047afb3b22beafce234f8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/86b808942c5047afb3b22beafce234f8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/86b808942c5047afb3b22beafce234f8_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/86b808942c5047afb3b22beafce234f8_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"allq2j1t7dh"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--stretch" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/18947/3b30bd08a3674f90b9b1e5989178502a_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":552}} srcSet={"https://cdn.swbpg.com/t/18947/3b30bd08a3674f90b9b1e5989178502a_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/18947/3b30bd08a3674f90b9b1e5989178502a_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/18947/3b30bd08a3674f90b9b1e5989178502a_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/18947/3b30bd08a3674f90b9b1e5989178502a_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/18947/3b30bd08a3674f90b9b1e5989178502a_s=2000x_.jpeg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box ff--1" content={"Dlouhé meetingy? Doplňte energii!<br>"}>
              </Subtitle>

              <Text className="text-box ff--2" content={"Zapomeňte na odpolední ospalost při dlouhých schůzkách, zpestřete si je naším office cateringem. Svačiny jsou navrženy tak, aby doplnily potřebnou energii.<br><br>Čerstvé kousky ovoce, wrapy, lahodné dezerty a další delikatesy jsou pečlivě vybrány tak, aby vám dodaly potřebnou sílu až do samého závěru pracovního dne. S naším cateringem se na dlouhé meetingy můžete plně soustředit, aniž byste museli přemýšlet o hladu nebo únavě.<br>"}>
              </Text>

              <Button className="btn-box fs--12" use={"page"} href={"/officecatering#vcbhlz9jitq"} style={{"maxWidth":1000}} content={"Chci objednat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l12w pb--60 pt--60" name={"m99cbb5xihg"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/18947/4bc34ecd0174498fb9fef1561103a8eb_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/4bc34ecd0174498fb9fef1561103a8eb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/4bc34ecd0174498fb9fef1561103a8eb_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/4bc34ecd0174498fb9fef1561103a8eb_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/4bc34ecd0174498fb9fef1561103a8eb_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/4bc34ecd0174498fb9fef1561103a8eb_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/18947/d6d9c5aa6204473fb5bbefc2752788f8_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/d6d9c5aa6204473fb5bbefc2752788f8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/d6d9c5aa6204473fb5bbefc2752788f8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/d6d9c5aa6204473fb5bbefc2752788f8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/d6d9c5aa6204473fb5bbefc2752788f8_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/18947/81585feeba0c4d81a3fd957e857f8ecf_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/81585feeba0c4d81a3fd957e857f8ecf_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/81585feeba0c4d81a3fd957e857f8ecf_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/81585feeba0c4d81a3fd957e857f8ecf_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/81585feeba0c4d81a3fd957e857f8ecf_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/18947/5da7941b50f549b386a6d375528963e2_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/5da7941b50f549b386a6d375528963e2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/5da7941b50f549b386a6d375528963e2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/5da7941b50f549b386a6d375528963e2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/5da7941b50f549b386a6d375528963e2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/5da7941b50f549b386a6d375528963e2_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" content={"<span style=\"font-weight: bold;\">Kvalitní suroviny</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" content={"<span style=\"font-weight: bold;\">Profesionální servis</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" content={"<span style=\"font-weight: bold;\">Doručení na čas</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" content={"<span style=\"font-weight: bold;\">Flexibilita a pohodlí</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"vcbhlz9jitq"}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--left ff--1" style={{"color":"var(--color-variable-1)"}} content={"Office catering připravíme podle<br>vašich přání"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":380}} content={"Dovolte nám ukázat, že každý okamžik ve vaší kanceláři může být jako malý festival chutí, který obohatí váš pracovní svět."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1 --right" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--20" style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--4 flex--center" columns={"4"} fullscreen={false}>
            
            <ColumnWrapper className="--right" style={{"maxWidth":215}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/18947/a0ef953d5c73431e80aa9839c213ae08_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":123}} srcSet={"https://cdn.swbpg.com/t/18947/a0ef953d5c73431e80aa9839c213ae08_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/a0ef953d5c73431e80aa9839c213ae08_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/a0ef953d5c73431e80aa9839c213ae08_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center" style={{"maxWidth":269}} content={"<span style=\"color: rgb(255, 255, 255);\"><span style=\"font-weight: bold;\">E-mail<br></span><br>info@olivacatering.cz</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(255, 255, 255);\"><span style=\"font-weight: bold;\">Telefon<br></span><br>+420 723 101 012</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center" content={"<span style=\"font-weight: 700; color: rgb(255, 255, 255);\">Sledujte nás</span><br>"}>
              </Text>

              <Text className="text-box text-box--center" content={"<a href=\"https://www.instagram.com/olivacatering_cz/\" style=\"color: rgb(255, 255, 255);\">Instagram</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}